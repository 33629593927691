@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
body {
  margin: 0;
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.reading {
  font-size: 17px;
  line-height: 180%; 
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 700
}